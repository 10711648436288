<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Solicitudes Vehiculos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tif</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Operación</li>
                  <li class="breadcrumb-item active">Solicitudes Vehiculos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-10"></div>
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        v-if="
                          $store.getters.can('tif.solicitudesVehiculos.show')
                        "
                        @click="direccionar()"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_solicitud_vehiculo_export"
                        v-if="
                          $store.getters.can('tif.solicitudesVehiculos.export')
                        "
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0 table-responsive">
                <table
                  id="solicitudes"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th class="text-center">
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @input="getIndex()"
                        />
                      </th>
                      <th class="text-center">
                        Bloque
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.bloque"
                          @input="getIndex()"
                        />
                      </th>
                      <th class="text-center">
                        Transportadora
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.empresa"
                          @input="getIndex()"
                        />
                      </th>
                      <th>
                        Fecha Inicial
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_ini"
                          @change="getIndex()"
                        />
                      </th>
                      <th>
                        Fecha Final
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_fin"
                          @change="getIndex()"
                        />
                      </th>
                      <th class="text-center">Observación</th>
                      <th class="text-center">
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th class="text-center">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="solicitud_vehiculo in solicitud_vehiculos.data"
                      :key="solicitud_vehiculo.id"
                    >
                      <td>{{ solicitud_vehiculo.id }}</td>
                      <td class="text-nowrap">
                        {{ solicitud_vehiculo.nombre }}
                      </td>
                      <td>{{ solicitud_vehiculo.bloque.nombre }}</td>
                      <td>{{ solicitud_vehiculo.empresa.razon_social }}</td>
                      <td class="text-center">
                        {{ solicitud_vehiculo.fecha_ini }}
                      </td>
                      <td class="text-center">
                        {{ solicitud_vehiculo.fecha_fin }}
                      </td>
                      <td class="text-wrap text-xs">
                        {{ solicitud_vehiculo.observacion }}
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            solicitud_vehiculo.estado == 1
                              ? 'badge-warning'
                              : solicitud_vehiculo.estado == 2
                              ? 'badge-success'
                              : 'badge-danger'
                          "
                          >{{ solicitud_vehiculo.nEstado }}</span
                        >
                      </td>
                      <td style="width: 50px" class="text-left">
                        <div class="btn-group float-left">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="
                              $store.getters.can(
                                'tif.solicitudesVehiculos.show'
                              )
                            "
                            @click="direccionar(solicitud_vehiculo)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="
                              $store.getters.can(
                                'tif.solicitudesVehiculos.delete'
                              ) && solicitud_vehiculo.estado == 1
                            "
                            @click="destroy(solicitud_vehiculo)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-warning"
                            v-if="
                              $store.getters.can(
                                'tif.solicitudesVehiculos.cancelarSolicitud'
                              ) && solicitud_vehiculo.estado != 3
                            "
                            @click="cancelarSoli(solicitud_vehiculo)"
                          >
                            <i class="fas fa-ban"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="solicitud_vehiculos.total">
                  <p>
                    Mostrando del <b>{{ solicitud_vehiculos.from }}</b> al
                    <b>{{ solicitud_vehiculos.to }}</b> de un total:
                    <b>{{ solicitud_vehiculos.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="solicitud_vehiculos"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
          <SolicitudVehiculoExport ref="SolicitudVehiculoExport" />
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
import SolicitudVehiculoExport from "../solicitudesVehiculos/SolicitudVehiculoExport";
import moment from "moment";

export default {
  name: "SolicitudesVehiculosIndex",
  components: {
    pagination,
    Loading,
    SolicitudVehiculoExport,
  },
  data() {
    return {
      solicitud_vehiculos: {},
      botonCancelar: false,
      filtros: {
        nombre: null,
        bloque: null,
        empresa: null,
        fecha_ini: null,
        fecha_fin: null,
      },
      listasForms: {
        estados: {},
      },
      cargando: false,
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/tif/solicitudesVehiculos?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.solicitud_vehiculos = response.data;
          this.cargando = false;
        });
    },

    getEstados() {
      axios.get("/api/lista/154").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    direccionar(solicitud_vehiculo = null) {
      let accion = "Crear";
      if (solicitud_vehiculo) {
        accion = "Editar";
      }
      return this.$router.push({
        name: "/Tif/SolicitudesVehiculosForm",
        params: {
          accion,
          solicitud_vehiculo,
        },
      });
    },

    destroy(solicitud_vehiculo) {
      this.$swal({
        title:
          "Esta seguro de eliminar la solicitud: " +
          solicitud_vehiculo.nombre +
          "?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/tif/solicitudesVehiculos/" + solicitud_vehiculo.id)
            .then(() => {
              this.cargando = false;
              this.$swal({
                icon: "success",
                title: "Se eliminó la solicitud exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.getIndex();
            });
        }
      });
    },

    cancelarSoli(solicitud_vehiculo) {
      this.fechaAct = moment().format("YYYY-MM-DD HH:mm:ss");
      this.fechaCorte = moment(solicitud_vehiculo.fecha_ini)
        .add(-1, "minute")
        .format("YYYY-MM-DD HH:mm:ss");

      if (this.fechaAct > this.fechaCorte) {
        this.$swal({
          title: "No es posible cancelar la solicitud..!",
          text: "Se excedió la fecha limite",
          icon: "error",
          confirmButtonText: "Aceptar!",
        });
      } else {
        this.$swal({
          title:
            "Esta seguro de cancelar la solicitud: " +
            solicitud_vehiculo.nombre +
            "?",
          text: "Los cambios no se pueden revertir!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Cancelar!",
        }).then((result) => {
          if (result.value) {
            axios({
              method: "PUT",
              url: "/api/tif/solicitudesVehiculos/cancelarSolicitud",
              data: solicitud_vehiculo,
            }).then(() => {
              this.cargando = false;
              this.$swal({
                icon: "success",
                title: "Se cancelo la solicitud exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.getIndex();
            });
          }
        });
      }
    },
  },
  mounted() {
    this.getIndex();
    this.getEstados();
  },
};
</script>
